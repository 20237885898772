import React from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import { registerLocale } from  "react-datepicker";
import { fr } from 'date-fns/locale/fr';

export default class Historique extends React.Component{

    month = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Decembre"];

    constructor(props){
        super(props);

        const Gardes = JSON.parse(localStorage.getItem('Gardes'));
       
        const d = new Date();
        const currentMonth = d.getMonth();

        this.state = {
            "Gardes" : Gardes,
            "CurrentMonth" : currentMonth
        };
        
        registerLocale('fr', fr);        
    }   
    
    componentWillReceiveProps(props) {
        const Gardes = JSON.parse(localStorage.getItem('Gardes'));
        this.setState({'Gardes': Gardes});
    } 

     
    calculerHeures(journee){
        let debut = new Date("01/01/2000 " + journee.debutGarde+":00");
        let fin = new Date("01/01/2000 " + journee.finGarde+":00");

        return ((fin-debut)/1000)/3600;;
    }

    calculerCoutNET(nbHeures){
        let coutHoraire = Number(localStorage.getItem('TauxHoraire'));       

        let total = (nbHeures * coutHoraire);
        return Number(total);
    }

    calculerCoutIE(isRepas){ 
        let IE          = Number(localStorage.getItem('IE'));
        let repas       = isRepas ? Number(localStorage.getItem('Repas')) : 0;

        let total = IE + repas;
        return Number(total);
    }

    deleteDayEntry(jour){
        let Gardes = JSON.parse(localStorage.getItem('Gardes'));
        delete Gardes[jour];
        localStorage.setItem('Gardes', JSON.stringify(Gardes));
        this.setState({'Gardes': Gardes});
    }

    afficherHeures(heureflottante){
        let hours = Math.floor(heureflottante);
        if(hours == heureflottante)
            return '' + hours + 'h';
        else {
            let minutes = (heureflottante * 60) - (hours * 60);
            return '' + hours + 'h' + minutes;
        }
    }


    render(){
        var options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};

        let content = {};
        let i = 0;
        let datas = {};

        if(this.state.Gardes != null){
            Object.keys(this.state.Gardes).forEach(jour => {
                Object.keys(this.state.Gardes[jour]).forEach(enfant => {
                    let currentMonth            = this.month[new Date(jour).getMonth()];
                    let nbHeures                = this.calculerHeures(this.state.Gardes[jour][enfant]);
                    let isRepas                 = this.state.Gardes[jour][enfant].aMange;
                    let coutNetJournalier       = this.calculerCoutNET(nbHeures);
                    let coutIERepasJournalier   = this.calculerCoutIE(isRepas);
                    let totalJournalier         = Number(coutNetJournalier) + Number(coutIERepasJournalier);
                    
                    // Init sum for current month
                    if(datas[currentMonth] == null){
                        datas[currentMonth] = {
                            hoursCount : 0,
                            daysCount : 0,
                            lunchesCount : 0,
                            salary : 0,
                            ieTotal : 0
                        };
                    }

                    datas[currentMonth].hoursCount      = Number(datas[currentMonth].hoursCount)    + Number(nbHeures); 
                    datas[currentMonth].daysCount       = Number(datas[currentMonth].daysCount)     + Number(1);
                    datas[currentMonth].lunchesCount    = Number(datas[currentMonth].lunchesCount)  + Number((isRepas ? 1 : 0));
                    datas[currentMonth].salary          = Number(datas[currentMonth].salary)        + Number(coutNetJournalier);
                    datas[currentMonth].ieTotal         = Number(datas[currentMonth].ieTotal)       + Number(coutIERepasJournalier);

                    // Init content for current month
                    if(content[currentMonth] == null)
                        content[currentMonth] = [];

                    content[currentMonth].push(<tr key={i++}>
                        <td>{enfant}</td>
                        <td>{new Date(jour).toLocaleDateString("fr-FR", options)}</td>
                        <td>{'' + this.afficherHeures(this.calculerHeures(this.state.Gardes[jour][enfant])) + ' (' + this.state.Gardes[jour][enfant].debutGarde + ' à ' + this.state.Gardes[jour][enfant].finGarde + ')'}</td>
                        <td>{this.state.Gardes[jour][enfant].aMange ? "Oui" : "Non"}</td>
                        <td>{Number(totalJournalier).toFixed(2) + '€'}</td>
                        <td><Button className="btn-danger" type="submit" onClick={e => {this.deleteDayEntry(jour)}}><FontAwesomeIcon icon={faTrash} /></Button></td>
                    </tr>);
                });
            });
        }

        
        console.log(Object.keys(content));

        return (            
            <Container>
                <h2>Historique</h2>
                <Tabs defaultActiveKey={this.month[this.state.CurrentMonth]}>
                    {Object.keys(content).map((month, i) => {
                        return (
                            <Tab key={i} eventKey={month} title={month}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Enfant</th>
                                            <th>Jour</th>
                                            <th>Nombre d'heures</th>
                                            <th>Avec repas ?</th>
                                            <th>Coût journalier</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {content[month]}
                                    </tbody>
                                </Table>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Nombre d'heures</th>
                                            <th>Nombre de jours</th>
                                            <th>Nombre de repas</th>
                                            <th>Salaire net</th>
                                            <th>IE + Repas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><b className="text-primary">{this.afficherHeures(datas[month].hoursCount)}</b></td>
                                            <td><b className="text-primary">{datas[month].daysCount}</b></td>
                                            <td><b className="text-primary">{datas[month].lunchesCount}</b></td>
                                            <td>{datas[month].hoursCount} x {Number(localStorage.getItem('TauxHoraire'))}€ = <b className="text-primary">{Number(datas[month].salary).toFixed(2)}€</b></td>
                                            <td>({datas[month].daysCount} x {Number(localStorage.getItem('IE'))}€) + ({datas[month].lunchesCount} x {Number(localStorage.getItem('Repas'))}€) = <b className="text-primary">{Number(datas[month].ieTotal).toFixed(2)}€</b></td>                                            
                                        </tr>
                                    </tbody>
                                </Table>
                            </Tab>
                        )
                    })}
                </Tabs>
            </Container>
        )
    }
}