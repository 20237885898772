// Import core component
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Import pages
import Home from './Views/Pages/Home';
import History from './Views/Pages/History';
import Error404 from './Views/Pages/Error404';

// Import template
import Template from './Views/Templates/Template'

class App extends React.Component {
	// ------------------------------------
	// -- Constructor
	/*constructor(props){
		super(props);
	}*/

	render(){
		return (
			<Template>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/home" element={<Home />} />
					<Route path="/History" element={<History />} />
					<Route path="*" element={<Error404 />} />
				</Routes>
			</Template>
		)
	}		

}

export default App;
