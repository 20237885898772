import React from 'react';

export default class Error404 extends React.Component{

    render(){
        return (
            <div class="d-flex align-items-center justify-content-center vh-100">
                <div class="text-center">
                    <h1 class="display-1 fw-bold">404</h1>
                    <p class="fs-3"> <span class="text-danger">Oups!</span> Cette page n'existe pas.</p>
                    <p class="lead">
                        La page que vous venez de charger n'existe pas. Revenez à l'accueil.
                    </p>
                    <a href="/home" class="btn btn-primary">Retour à l'accueil</a>
                </div>
            </div>
            )
    }
}