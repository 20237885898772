import React from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default class Enfant extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            nomEnfant : props.nomEnfant,
            idEnfant : props.idEnfant
        };
    }

    setNomEnfant(nomEnfant){
        this.setState({"nomEnfant" : nomEnfant});
        let enfants = JSON.parse(localStorage.getItem('Enfants'));
        if(enfants != null){
            let i = 0;
            let indice = -1;
            enfants.forEach(enfant =>{
                if(enfant.idEnfant == this.state.idEnfant)
                    indice = i;
                ++i;
            });            
            if(indice != -1){
                enfants[indice].nomEnfant = nomEnfant;
                localStorage.setItem('Enfants', JSON.stringify(enfants));
            }
        }        
    }

    removeEnfant(){
        let enfants = JSON.parse(localStorage.getItem('Enfants'));
        if(enfants != null){
            let i = 0;
            let indice = -1;
            enfants.forEach(enfant =>{
                if(enfant.idEnfant == this.state.idEnfant)
                    indice = i;
                ++i;
            });

            if(indice != -1){
                enfants.splice(indice, 1);
                localStorage.setItem('Enfants', JSON.stringify(enfants));
            }
        }
    }

    render(){
        return (
            <Form>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">                   
                    <Form.Control type="text" placeholder="Entrez le nom et prénom." value={this.state.nomEnfant} onChange={e => this.setNomEnfant(e.target.value)} />
                    <Button type="submit" className="btn-danger" onClick={e => this.removeEnfant()}><FontAwesomeIcon icon={faTrash} /></Button>
                </Form.Group>
            </Form>
        )
    }
}