import React from 'react';

import Historique from './Historique';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Alert from 'react-bootstrap/Alert';

import DatePicker from 'react-datepicker';
import TimePicker from 'react-bootstrap-time-picker';
import { registerLocale } from  "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-notifications/lib/notifications.css';

export default class Horaires extends React.Component{

    constructor(props){
        super(props);
        
        let nomEnfant = '';
        let debutGarde = "10:00";
        let finGarde = "19:00";

        let currentDate = new Date();
        const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });
        const jour = formatter.format(currentDate);
         
        this.state = {
            jour : jour,
            debutGarde: debutGarde,
            finGarde : finGarde,
            nomEnfant : nomEnfant,
            alertMsg : '',
            aMange : false,
            isUpdated : false
        };
        
        registerLocale('fr', fr)
    }

    initGarde(jour = null){
        let nomEnfant = this.state.nomEnfant;

        if(nomEnfant == '') {
            this.setState({"alertMsg" : "Veuillez choisir un enfant avant de poursuivre."});
        } else {
            let Gardes = {};
            if(localStorage.getItem('Gardes') != null){
                Gardes = JSON.parse(localStorage.getItem('Gardes'));
            }
            
            if(jour == null)
                jour = this.state.jour;

            let nomEnfant = this.state.nomEnfant;

            if(!(Object.keys(Gardes).includes(jour))){
                Gardes[jour] = {};

                Gardes[jour][nomEnfant] =  {
                    debutGarde : '10:00',
                    finGarde : '19:00',
                    aMange : this.state.aMange
                };
            } 

            return Gardes;
        }
    }

    timeTohour(time){
        let hour = Math.floor(time / 3600);
        let min = Math.floor((time - (hour * 3600)) / 60);

        if(hour < 10)
            hour = "0" + hour;

        if(min < 10)
            min = "0" + min;

        return "" + hour + ":" + min;
    }

    setJour(currentJour){
        const nomEnfant = this.state.nomEnfant;

        if(nomEnfant == ''){
            NotificationManager.error("Veuillez choisir un enfant avant de poursuivre.", 'Erreur');
        } else {
            const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });
            const jour = formatter.format(currentJour);
   
            let Gardes = this.initGarde(jour);
            localStorage.setItem('Gardes', JSON.stringify(Gardes));            
            
            this.setState({
                "jour" : jour,
                "debutGarde" : Gardes[jour][nomEnfant].debutGarde,
                "finGarde" : Gardes[jour][nomEnfant].finGarde,
                "aMange" : Gardes[jour][nomEnfant].aMange,
                isUpdated : (!this.state.isUpdated)
            });
        }
    }

    setDebutGarde(time){
        const nomEnfant = this.state.nomEnfant;

        if(nomEnfant == ''){           
            NotificationManager.error("Veuillez choisir un enfant avant de poursuivre.", 'Erreur');
        } else {
            let Gardes = this.initGarde();

            let timeInHour = this.timeTohour(time);
            Gardes[this.state.jour][nomEnfant].debutGarde = timeInHour;        
            Gardes[this.state.jour][nomEnfant].finGarde = this.state.finGarde; 
            Gardes[this.state.jour][nomEnfant].aMange = this.state.aMange;
            localStorage.setItem('Gardes', JSON.stringify(Gardes));

            this.setState({
                "debutGarde" : timeInHour,                
                isUpdated : (!this.state.isUpdated)
            });
        }

        NotificationManager.success("L'heure de début de garde a bien été mise à jour.", 'Mise à jour réussie');
    }

    setFinGarde(time){     
        const nomEnfant = this.state.nomEnfant;

        if(nomEnfant == ''){            
            NotificationManager.error("Veuillez choisir un enfant avant de poursuivre.", 'Erreur');
        } else {  
            let Gardes = this.initGarde();

            let timeInHour = this.timeTohour(time);
            Gardes[this.state.jour][nomEnfant].debutGarde =  this.state.debutGarde;     
            Gardes[this.state.jour][nomEnfant].finGarde = timeInHour;
            Gardes[this.state.jour][nomEnfant].aMange = this.state.aMange;
            localStorage.setItem('Gardes', JSON.stringify(Gardes));

            this.setState({
                "finGarde" : timeInHour,                
                isUpdated : (!this.state.isUpdated)
            });
        }
        
        NotificationManager.success("L'heure de fin de garde a bien été mise à jour.", 'Mise à jour réussie');
    }

    setEnfant(nomEnfant){
        let debutGarde = this.state.debutGarde;
        let finGarde = this.state.finGarde;
        let jour = this.state.jour;
        let aMange = this.state.aMange;

        if(localStorage.getItem('Gardes') != null){
            let Gardes = JSON.parse(localStorage.getItem('Gardes'));
            if(Object.keys(Gardes).includes(jour)){
                if(Object.keys(Gardes[jour]).includes(nomEnfant)){
                    debutGarde = Gardes[jour][nomEnfant].debutGarde;
                    finGarde = Gardes[jour][nomEnfant].finGarde;
                    aMange = Gardes[jour][nomEnfant].aMange;
                }
            }
        }

        this.setState({
            debutGarde : debutGarde,
            finGarde : finGarde,
            nomEnfant: nomEnfant, 
            "alertMsg" : '',
            aMange : aMange
        });
    }

    aMange(ouiNon){
        const nomEnfant = this.state.nomEnfant;

        if(nomEnfant == ''){            
            NotificationManager.error("Veuillez choisir un enfant avant de poursuivre.", 'Erreur');
        } else {
            let Gardes = this.initGarde();
            Gardes[this.state.jour][nomEnfant].aMange = ouiNon;
            localStorage.setItem('Gardes', JSON.stringify(Gardes));

            this.setState({
                aMange: ouiNon,
                isUpdated : (!this.state.isUpdated)
            });            
        }

        NotificationManager.success('La garde a bien été modifiée', 'Mise à jour réussie');
    }

    render(){
        let options = [];
        if(localStorage.getItem('Enfants') != null){
            let enfants = JSON.parse(localStorage.getItem('Enfants'));
            enfants.forEach(enfant => {
                options.push(<option key={enfant.idEnfant} value={enfant.nomEnfant}>{enfant.nomEnfant}</option>)
            });
        }       

        let CheckBox;
        if(this.state.aMange)
            CheckBox = <Form.Check type="switch" checked={true} id="custom-switch" label="L'enfant a t-il mangé ?" disabled={this.state.nomEnfant == ''} onChange={e => this.aMange(e.target.checked)} /> 
        else
            CheckBox = <Form.Check type="switch" id="custom-switch" label="L'enfant a t-il mangé ?" disabled={this.state.nomEnfant == ''} onChange={e => this.aMange(e.target.checked)} /> 
        
        return (
            <>
            <Container>
                <h2>Horaires de garde</h2>
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <InputGroup className="mb-3">
                            <Form.Select aria-label="Default select example" onChange={(e) => this.setEnfant(e.target.value)}>
                                <option>Quel enfant est gardé ?</option>
                                {options}
                            </Form.Select>                            
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Date</InputGroup.Text>
                            <DatePicker locale="fr" dateFormat="d MMMM yyyy" selected={this.state.jour} onChange={(jour) => this.setJour(jour)} showIcon toggleCalendarOnIconClick disabled={this.state.nomEnfant == ''} />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Début de garde</InputGroup.Text>
                            <TimePicker locale="fr-fr" format={24} onChange={(time) => this.setDebutGarde(time) } value={this.state.debutGarde} start="07:00" end="22:00" disabled={this.state.nomEnfant == ''} />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Fin de garde</InputGroup.Text>
                            <TimePicker locale="fr-fr" format={24} onChange={(time) => this.setFinGarde(time)} value={this.state.finGarde} start="05:00" end="22:00" disabled={this.state.nomEnfant == ''} />
                        </InputGroup> 
                        {CheckBox}               
                    </Form.Group>             
                </Form>  
            </Container>
            <Historique isUpdated={this.state.isUpdated} />
            <NotificationContainer/>
            </>
        )
    }
}