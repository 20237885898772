import React from 'react';

import Nounou from '../../ReactComponents/Nounou';
import Enfants from '../../ReactComponents/Enfants';
import Horaires from '../../ReactComponents/Horaires';

export default class Home extends React.Component{

    render(){
        return (
            <>
               <Nounou />
               <Enfants />
               <Horaires />
            </>
        )
    }
}