import React from 'react';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export default class Nounou extends React.Component{

    constructor(props){
        super(props);

        const NomNounou     = localStorage.getItem('NomNounou');
        const TauxHoraire   = localStorage.getItem('TauxHoraire');
        const IE            = localStorage.getItem('IE');
        const Repas         = localStorage.getItem('Repas');

        this.state = {
            NomNounou    : NomNounou,
            TauxHoraire  : TauxHoraire,
            IE           : IE,
            Repas        : Repas
        };

    }

    setNomNounou(NomNounou){
        this.setState({"NomNounou" : NomNounou});
        localStorage.setItem('NomNounou', NomNounou);
    }

    setTauxHoraire(TauxHoraire){
        this.setState({"TauxHoraire" : TauxHoraire});
        localStorage.setItem('TauxHoraire', TauxHoraire);
    }

    setIE(IE){
        this.setState({"IE" : IE});
        localStorage.setItem('IE', IE);
    }

    setRepas(Repas){
        this.setState({"Repas" : Repas});
        localStorage.setItem('Repas', Repas);
    }

    render(){
        return (
            <Container>
                <h2>Nounou</h2>                
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Entrez le nom et prénom." value={this.state.NomNounou} onChange={e => this.setNomNounou(e.target.value)} />
                    </Form.Group>
                    <InputGroup className="mb-3">
                        <Form.Control placeholder="Taux horaire" value={this.state.TauxHoraire} onChange={e => this.setTauxHoraire(e.target.value)} />
                        <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Control placeholder="Indemnités Entretiens (IE)" value={this.state.IE} onChange={e => this.setIE(e.target.value)} />
                        <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Control placeholder="Repas" value={this.state.Repas} onChange={e => this.setRepas(e.target.value)} />
                        <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup>
                </Form>
            </Container>
        )
    }
}