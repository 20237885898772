import React from 'react';

import Container from 'react-bootstrap/Container';

export default class History extends React.Component{

    render(){
        return (
            <Container>
               <p>En cours...</p>
            </Container>
        )
    }
}