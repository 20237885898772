import React from 'react'

import Menu from './Menu'

export default class Template extends React.Component{
    // -------------------------
    // -- ReactJS methods
    render(){
        return (
            <div className='template'>
                <header>
                    <Menu />
                </header>
                <section id="main-content" className="container-fluid">{ this.props.children }</section>
            </div>
        )
    }

}
