import React from 'react'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export default class Menu extends React.Component{
    // -------------------------
    // -- ReactJS methods
	render(){
		return (
			<>
				<Navbar collapseOnSelect expand="sm" bg="dark" data-bs-theme="dark">
					<Container>
						<Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="me-auto">
								<Nav.Link href="/home">Accueil</Nav.Link>
								<Nav.Link href="/history">Historique</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</>
		)
	}
}