import React from 'react';

import Enfant from './Enfant';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


export default class Enfants extends React.Component{

    constructor(props){
        super(props);

        let enfants = [];
        if(localStorage.getItem('Enfants') != null)
            enfants = JSON.parse(localStorage.getItem('Enfants'));

        this.state = {
            Enfants : enfants
        };
    }

    addEnfant(){
        let nomEnfant = this.nomEnfant.value;
        let enfants = [];
        let lastId = -1;

        if(localStorage.getItem('Enfants') != null){
            enfants = JSON.parse(localStorage.getItem('Enfants'));
            lastId = enfants[enfants.length -1].idEnfant;
        }

        let currentEnfant = {
            idEnfant : lastId +1,
            nomEnfant : nomEnfant
        };
        enfants.push(currentEnfant);
        localStorage.setItem('Enfants', JSON.stringify(enfants));

        this.setState({
            Enfants : enfants
        });
    }

    render(){
        let content = [];
        if(this.state.Enfants != null){
            this.state.Enfants.forEach(enfant => {
                content.push( <Enfant idEnfant={enfant.idEnfant} nomEnfant={enfant.nomEnfant} key={enfant.idEnfant} />)
            });
        }

        return (
            <Container>
                <h2>Enfants</h2>
                {content}
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Entrez le nom et prénom." ref={nomEnfant => (this.nomEnfant = nomEnfant)} />                            
                        <Button type="submit" onClick={e => this.addEnfant()}>Ajouter un enfant</Button>
                    </Form.Group>
                </Form>
            </Container>
        )
    }
}